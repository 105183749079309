export const environment = {
    production: true,
    title: 'brakar.citybike.cloud',
    mcs_template: 'CITYBIKEB',
    main_url: 'https://api.brakar.citybike.cloud',
    api_url: 'https://api.brakar.citybike.cloud/api',
    s3_image_url: 'https://mcs-citybike.s3-eu-west-1.amazonaws.com/',
    logo_title: 'CityBike - Brakar',
    map_center_position: [10.203262316063046, 59.743094819663355],
    enable_insight: false
};
